import { Box } from '@mui/material';
import React from 'react';

interface BoxUIProps {
  children?: React.ReactNode; // Especifica que `children` puede ser cualquier contenido React
  sx?: object;              // Define el tipo de `sx` como opcional
}

const BoxUI: React.FC<BoxUIProps> = ({ children, sx }) => {
  return (
    <Box
      component="section"
      sx={{
        maxWidth:'400px',
        display:'flex',
        background: '#D9D9D9',
        width: '100%',
        maxHeight:'600px',
        height:'100%',
        ...sx, // Combina las propiedades `sx` personalizadas con las predeterminadas
      }}
    >
      {children}
    </Box>
  );
};

export default BoxUI;
