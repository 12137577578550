import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';

interface propsButton{
    variant?: 'text' | 'contained' | 'outlined';
    title?: string;
    icon?: any;
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info';
    size?: "small" | "large" | "medium"
    onClick?: any
}

export default function ButtonUI({ variant, title, icon, color, size, onClick}: propsButton) {


  return (
    <Stack spacing={2} direction="row">
      <StyledButton onClick={onClick} size={size}  color={color} variant={variant ? variant : 'contained'} startIcon={icon ? icon : <></>}>{title}</StyledButton>
    </Stack>
  );
}


const StyledButton = styled(Button)(({ theme, color }) => ({
  fontWeight:'bolder',
  backgroundColor: color === 'primary'  ? '#1A455F' : color === 'secondary' ? '#EEEEEE' : 'inherit',
  borderColor: color === 'primary'  ? '#fafafa'  :  color === 'secondary' ? '#43a047'  : 'inherit',
  borderRadius: '10px',
  color: color === 'primary'  ? '#fafafa'  :  color === 'secondary' ? '#43a047'  : 'inherit',
  '&:hover': {
    backgroundColor: color === 'primary' ? '#00350392'  : color === 'secondary' ? '#00001' : 'inherit',
  },
}));