import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import ButtonUI from "../button/buttons";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { useState } from "react";
import { InputDatePicker } from "../inputsDatePicker/inputDatePicker";
import SearchIcon from "@mui/icons-material/Search";
import { Search } from "@mui/icons-material";

interface Column {
  id: string;
  label: string;
  align?: "right" | "left" | "center";
  render?: (value: any) => JSX.Element; // Permite pasar JSX en las celdas
}

interface TableProps {
  columns: Column[];
  rows: any[];
  loading?: boolean;
  isDate?: boolean;
  id?: string;
  valueInput?: string;
  onChangeInput?: any;
  report?: boolean;
  iconButton?: any;
  rowsPerPage?: any;
  setRowsPerPage?: any;
  page?: any;
  setPage?: any;
  totalRows?: any;
  startDate?: Date | null;
  endDate?: Date | null;
  setStartDate?: any;
  setEndDate?: any;
  onClickSearch?: any;
  isSearch?: boolean;
}

export default function TableMaterial({
  columns,
  loading,
  id,
  report,
  rows,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  totalRows,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isDate,
  valueInput,
  onChangeInput,
  onClickSearch,
  isSearch = true
}: TableProps) {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("created_at");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Volver a la primera página cuando cambie el número de filas por página
  };

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    saveAs(blob, "reporte.xlsx");
  };

  return (
    <Paper>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12} sm={2} rowSpacing={4}>
            {report && (
              <div style={{ marginRight: "25px" }}>
                <ButtonUI
                  color="primary"
                  icon={<SimCardDownloadIcon sx={{ fontSize: 40 }} />}
                  onClick={() => exportToExcel()}
                  title="Generar Reporte"
                />
              </div>
            )}
          </Grid>
{isSearch &&
          <Grid item xs={12} sm={2} padding={2}>
            <TextField
              id="outlined-basic"
              label="Busqueda por email"
              variant="standard"
              value={valueInput}
              onChange={(e: any) => onChangeInput(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="search" onClick={onClickSearch}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>}

          {isDate && (
            <InputDatePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          )}
        </Grid>
      </Container>

      <TableContainer id={id}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="400px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Table sx={{ minWidth: 250 }} aria-label="reusable table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align || "left"}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align || "left"}>
                        {column.render
                          ? column.render(row[column.id])
                          : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={totalRows}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </>
        )}
      </TableContainer>
    </Paper>
  );
}
