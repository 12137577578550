import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Stack } from "@mui/material";
import axios from "axios";
import SnackBar from "../../components/notification/snackbar/snackbar";
import { useFetch } from "../../hooks/customFetch/useCustomFetch";

const apiUrl = process.env.REACT_APP_API_URL;

export const FormUser = ({ data }: any) => {
  const userData = data?.data?.data || {};
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [street, setStreet] = useState("");
  const [extNumber, setExtNumber] = useState("");
  const [intNumber, setIntNumber] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [reference, setReference] = useState("");

  const [postalCodeGeo, setPostalCodeGeo] = useState("");

  const { fetchData, error, loading } = useFetch(
    "https://geocodes.envia.com/zipcode/MX/" + parseInt(postalCodeGeo),
    postalCode
  );

  const onChangeZip = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPostalCodeGeo(e.target.value);
  };

  useEffect(() => {
    if (data?.data?.response?.data?.address) {
      const userData = data?.data?.response?.data || {};
      const addressData = userData?.address || {};

      setFirstName(userData?.first_name || "");
      setLastName(userData?.last_name || "");
      setPhone(userData?.phone || "");
      setPostalCode(addressData?.postal_code || "");
      setCountry(addressData?.country || "");
      setStreet(addressData?.street || "");
      setExtNumber(addressData?.ext_number || "");
      setIntNumber(addressData?.int_number || "");
      setDistrict(addressData?.district || "");
      setCity(addressData?.city || "");
      setState(addressData?.state || "");
      setReference(addressData?.reference || "");
    }
  }, [data]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const formData = {
      first_name: firstName,
      last_name: lastName,
      phone,
      email: userData.email, // Se obtiene del objeto `userData`
      address: {
        postal_code: postalCode,
        country,
        street,
        int_number: intNumber,
        ext_number: extNumber,
        district,
        city,
        state,
        reference,
      },
    };

    const storedToken = sessionStorage.getItem("userData");

    try {
      const response = await axios.patch(`${apiUrl}/api/v1/profile`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storedToken}`, // Token de autorización
        },
      });
      if (response.data) {
        setMessage("Datos Actulizados");
        setOpen(true);
      }
    } catch (error) {
      setMessage("Error al actualizar los datos");
      setOpen(true);
    }
  };

  useEffect(() => {
    if (fetchData !== null && postalCodeGeo.length > 4) {
      setCity(fetchData[0]?.locality || "N/A");
      setCountry(fetchData[0]?.country.name || "N/A");
      setState(fetchData[0]?.state.name || "N/A");
      setDistrict(fetchData[0]?.suburbs[0] || "N/A");
      setStreet(fetchData[0]?.additional_info?.street || "N/A");
    }
  }, [fetchData, postalCodeGeo]);
  return (
    <Container maxWidth="sm">
      <h2>Datos Personales</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Nombre"
          onChange={(e) => setFirstName(e.target.value)}
          value={firstName}
          fullWidth
          required
          sx={{ mb: 4 }}
        />

        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Apellido"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          fullWidth
          required
          sx={{ mb: 4 }}
        />

        <Stack spacing={2} direction="row" sx={{ mb: 4 }}>
          <TextField
            type="tel"
            variant="outlined"
            color="secondary"
            label="Número Telefónico"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            fullWidth
            required
          />
        </Stack>

        <h2>Dirección</h2>

        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Código Postal"
          onChange={(e) => {
            setPostalCode(e.target.value);
            onChangeZip(e);
          }}
          value={postalCode}
          fullWidth
          required
          sx={{ mb: 4 }}
        />

        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="País"
          onChange={(e) => setCountry(e.target.value)}
          value={country}
          fullWidth
          required
          sx={{ mb: 4 }}
        />

        <TextField
          type="text"
          variant="outlined"
          color="secondary"
          label="Calle"
          onChange={(e) => setStreet(e.target.value)}
          value={street}
          fullWidth
          required
          sx={{ mb: 4 }}
        />

        <Stack spacing={2} direction="row" sx={{ mb: 4 }}>
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Número Exterior"
            onChange={(e) => setExtNumber(e.target.value)}
            value={extNumber}
            fullWidth
            required
          />
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Número Interior"
            onChange={(e) => setIntNumber(e.target.value)}
            value={intNumber}
            fullWidth
          />
        </Stack>

        <Stack spacing={2} direction="row" sx={{ mb: 4 }}>
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Colonia"
            onChange={(e) => setDistrict(e.target.value)}
            value={district}
            fullWidth
            required
          />
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Municipio"
            onChange={(e) => setCity(e.target.value)}
            value={city}
            fullWidth
            required
          />
        </Stack>

        <Stack spacing={2} direction="row" sx={{ mb: 4 }}>
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Estado"
            onChange={(e) => setState(e.target.value)}
            value={state}
            fullWidth
            required
          />
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Referencia"
            onChange={(e) => setReference(e.target.value)}
            value={reference}
            fullWidth
          />
        </Stack>

        <Button variant="outlined" color="secondary" type="submit" fullWidth>
          Guardar
        </Button>
      </form>
      <SnackBar
        onClose={() => setOpen(false)}
        open={open}
        message={message}
      ></SnackBar>

    </Container>
  );
};
