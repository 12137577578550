import { useState } from "react";
import CardCustom from "../../components/card";
import TableCustom, { TableCustomProps } from "../../components/table/table";
import PaginationCustom from "../../components/pagination";
import { LottiesComponent } from "../../components/loadingLotties/LottiesComponent";
import cardLottie from "../../assets/lottie/target.json";
import useFetchTransactions from "../../service/userTransactions/getTransaction";
import { Transactions } from "../../const/transaction";
import TableMaterial from "../../components/tableMaterial/tableMaterial";
import ButtonUI from "../../components/button/buttons";
import { Stack, Typography } from "@mui/material";
import AccountFormulary from "../../components/withdrawals";
import { BagStatusCard } from "../../components/bags/bagsStatusCard/BagStatusCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BoxUI from "../../components/common/boxUI/BoxUI";
import useFetchBank from "../../service/userBankAccount/getUserBank";
import { log } from "node:console";

const BankAccount = () => {
  const storedToken = sessionStorage.getItem("userData");
  const { accountBank, loading, error } = useFetchBank(storedToken, "");

  const [page, setPage] = useState(1);
  const itemsPerPage = 8;

  const transformDataToTable = (
    transferences: any[]
  ): TableCustomProps["rows"] => {

    
    return transferences?.map((transaction) => ({
      name: transaction.name,
      email: transaction.email,
      clabe: transaction.clabe,
      date: new Date(transaction?.created_at).toLocaleDateString("es-MX", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }),

      // status: (
      //   <div className={`status ${transaction.status.toLowerCase()}`}>
      //     {translateStatus(transaction.status)}
      //   </div>
      // ),
    }));
  };

  const dataTable: any = {
    columns: [
      { id: "name", label: "Nombre" },
      { id: "email", label: "Email" },
      { id: "clabe", label: "Clabe" },
      { id: "date", label: "Fecha" },
    ],
    rows: transformDataToTable(
      accountBank?.data ? accountBank?.data[0]?.clients : []
    ),
  };

  const hasData = dataTable?.rows.length > 0;

  // const columns: any = [
  //   { id: "name", label: "Nombre" },
  //   { id: "email", label: "Correo", align: "right" },
  //   { id: "clabe", label: "Clabe", align: "right" },
  //   { id: "created_at", label: "Fecha", align: "right" },
  //   { id: "bank", label: "Banco", align: "right" },

  //   {
  //     id: "action",
  //     label: "Estado",
  //     align: "center",
  //     render: (value: any) => <BagStatusCard status={value} />,
  //   },
  // ];


  
  return (
    <div>
      {hasData ? (
        <div>
          <h2>Cuentas Bancarias</h2>
          {/* <Stack spacing={2} direction="row">
            <ButtonUI
              size="small"
              color="primary"
              variant="outlined"
              title="Descargar Reporte"
            />
            <AccountFormulary></AccountFormulary>
          </Stack> */}
          {/* <TableMaterial columns={columns} rows={rows} />; */}
          {/* <BoxUI>
            <Typography>Numero de cuenta</Typography>

            <Typography>
              {accountBank?.data ? accountBank?.data[0]?.main_clabe : ""}
            </Typography>
          </BoxUI> */}
          <Stack spacing={2} direction="row">
            {/* <ButtonUI
              icon={<AddCircleOutlineIcon />}
              size="small"
              color="primary"
              variant="outlined"
              title="Agregar cuenta"
            /> */}
            {/* <AccountFormulary></AccountFormulary> */}
          </Stack>
          <TableMaterial isSearch ={ false} rowsPerPage={5} page={1} totalRows={5} loading={loading} columns={dataTable?.columns} rows={dataTable?.rows} />
        </div>
      ) : (
        <div>
          <h2>Cuentas Bancarias</h2>
          <BoxUI>
            <Typography>Numero de cuenta</Typography>

            <Typography>
              {accountBank?.data ? accountBank?.data[0]?.main_clabe : ""}
            </Typography>
          </BoxUI>
          <Stack spacing={2} direction="row">
            <ButtonUI
              icon={<AddCircleOutlineIcon />}
              size="small"
              color="primary"
              variant="outlined"
              title="Agregar cuenta"
            />
            {/* <AccountFormulary></AccountFormulary> */}
          </Stack>
          <TableMaterial loading={loading} columns={dataTable?.columns} rows={dataTable?.columns.rows} />
        </div>
        // <LottiesComponent
        //   style={{ width: "400px" }}
        //   title="Aun no tienes transacciones. Integra nuestra API en tu sitio y administra tus primeras transacciones"
        //   lotties={cardLottie}
        // />
      )}
    </div>
  );
};

export default BankAccount;
