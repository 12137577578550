// hooks/useFetchTransactions.ts
import { useState, useEffect } from "react";
import axios from "axios";
import { TransactionResponse } from "../../const/transaction";

const apiUrl = process.env.REACT_APP_API_URL;

const useFetchBank = (
  token: any,
  email: string,
//   _page: number,
//   _pageSize: number,
//   filter: any
) => {
  const [accountBank, setAccountBank] = useState< any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get<any>(
          `https://api.pagosfiable.com/api/v1/transference`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAccountBank(response?.data?.data);
      } catch (err) {
        console.error("Error fetching transactions:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [token]);


// }, [token, _page, _pageSize, filter]);
  return { accountBank, loading, error };
};

export default useFetchBank;
