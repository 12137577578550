import { useEffect, useState } from "react";

export const useFetch = (url: string, value: string, delay: number = 500) => {
  const [fetchData, setFetchData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | Error>(null);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);


  useEffect(() => {
    if (debouncedValue.length > 2) {
      const fetchDataFromUrl = async () => {
        setLoading(true);
        setError(null);

        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
          const data = await response.json();
          setFetchData(data);
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromUrl();
    }
  }, [debouncedValue, url]);

  return { fetchData, loading, error };
};
